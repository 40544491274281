/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e1e7e5,
    100 : #b3c3bf,
    200 : #809b94,
    300 : #4d7369,
    400 : #275549,
    500 : #013729,
    600 : #013124,
    700 : #012a1f,
    800 : #012319,
    900 : #00160f,
    A100 : #56ffb2,
    A200 : #23ff9b,
    A400 : #00ef82,
    A700 : #00d574,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #fff3e0,
    100 : #ffe0b3,
    200 : #ffcc80,
    300 : #ffb74d,
    400 : #ffa726,
    500 : #ff9800,
    600 : #ff9000,
    700 : #ff8500,
    800 : #ff7b00,
    900 : #ff6a00,
    A100 : #ffffff,
    A200 : #fff7f2,
    A400 : #ffd6bf,
    A700 : #ffc5a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);