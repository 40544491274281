@import './app-theme.scss';
body{
    margin: 0px;   
   }
   .mat-button {
    font-weight: 600;
    letter-spacing: 1px;
   }
   .body{
       background: rgb(255, 255, 255);
       font-family: 'Gotham Book, sans-serif';
       margin: 0px;
   }.form-container{
       margin-top: 5px;
   }.app{
       height: 100vh;
       background: transparent;
   }.router-content{
       max-width: 1100px;
       margin-left: auto;
       margin-right: auto;
       padding-top: 10px;
       padding-left: 5px;
       padding-right: 5px;
   }.button-row{
       width: 100%;
       display: flex;
       flex-direction: row;
       align-items: flex-start;
       margin-top: 5px;
   }.mat-sidenav-icon{
       margin-right: 25px;
   }.normal{
       margin: 0px;
   }.mat-nav-list {
       padding-top: 0px;
   }.mat-table::-webkit-scrollbar{
       width: 8px;
       height: 8px;
       background-color: #FFCDD2;
   }.mat-table::-webkit-scrollbar-thumb {
       background: #013729; 
   }.mat-list::-webkit-scrollbar {
       width: 8px;
       height: 8px;
       background-color: #FFCDD2;
   }.mat-list::-webkit-scrollbar-thumb {
       background: #013729; 
   }.mat-drawer-inner-container::-webkit-scrollbar {
       width: 4px;
       height: 4px;
       background-color: #c5d3d1;
   }.mat-drawer-inner-container:hover::-webkit-scrollbar {
       width: 2px;
       height: 2px;
       border-radius: 10px;
       background-color: #c5d3d1;
   }.mat-sidenav-content::-webkit-scrollbar {
       width: 2px;
       height: 2px;
       background-color: #FFCDD2;
   }.mat-drawer-inner-container::-webkit-scrollbar-thumb{
       background: #013729; 
   }.mat-sidenav-content::-webkit-scrollbar-thumb{
       background: #013729; 
   }.mat-autocomplete-panel::-webkit-scrollbar {
       width: 2px;
       height: 2px;
       background-color: #FFCDD2;
   }.mat-autocomplete-panel::-webkit-scrollbar-thumb{
       background: #013729; 
   }.mat-drawer-inner-container{
    min-width: 230px;
    }   
   .mat-drawer-container
   {
    background-color: #ffffff;
   }
   .featurebar-title {
       color: #fff;
       font-size: 26px;
       font-weight: 500;
       line-height: 32px;
       overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap;
       
     }
   
     $icon-small-container-size: 32px;
   $icon-small-size: 16px;
   
   .mat-icon-button.small {
     width: $icon-small-container-size;
     height: $icon-small-container-size;
     line-height: $icon-small-container-size;
   
     .mat-icon, .mat-icon-button i {
       line-height: $icon-small-size;
     }
   
     .mat-icon {
       width: $icon-small-size;
       height: $icon-small-size;
     }
   
     .material-icons {
       font-size: $icon-small-size;
     }
   
   
   }
   
   .mat-button.filial {
       background: rgba($color: #000000, $alpha: 0.2);
       color: #EEEEEE;
   }
   
   .font-18 {
       font-size: 18px;
       height: 18px; 
       width: 18px; 
   }
   
   .mat-icon{
       margin-right: 0px !important;
   }
   .mat-card{
        border-radius: 20px !important;
    }
   .mat-card-fit{
       padding: 0px !important;
   }
   
   .mat-toolbar-row{
       height: 48px;
   }
   
   .mat-toolbar-multiple-rows {
       min-height: 48px;
   }
   
   .p6n-action-bar-title {
       font-weight: 400;
       letter-spacing: .005em;
       font-size: 18px;
       line-height: 20px;
       margin-left: 8px;
   }
   
   .mat-button-float{
       position: fixed; 
       right: 16px; 
       bottom: 16px;
   }
   
   .mat-form-field-infix {
       width: unset !important;
   }

   .mat-cell {
    color: white;
}
.mat-dialog-container {
    @include angular-material-typography($custom-typography);
  }

  
mat-footer-row, mat-row {
    min-height: 40px !important;
}

.campanha-termo-aceite-pendente {
    border-color: darkorange;
    box-sizing: border-box;
    border-width: 2px 2px 2px 10px;
    border-style: solid;
}

.campanha-termo-aceite-pendente:hover,
.campanha-termo-aceite-pendente:focus,
.campanha-termo-aceite-pendente:active {
    border-color: darkorange;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 10px darkorange !important;
    border-width: 2px 2px 2px 10px;
    border-style: solid;
}

.campanha-termo-aceite-recusado {
    border-color: red;
    box-sizing: border-box;
    border-width: 2px 2px 2px 10px;
    border-style: solid;
}

.campanha-termo-aceite-recusado:hover,
.campanha-termo-aceite-recusado:focus,
.campanha-termo-aceite-recusado:active {
    border-color: red;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 10px red !important;
    border-width: 2px 2px 2px 10px;
    border-style: solid;
}

.campanha-termo-aceite-aceito {
    border-color: #013729;
    box-sizing: border-box;
    border-width: 2px 2px 2px 10px;
    border-style: solid;
}

.campanha-termo-aceite-aceito:hover,
.campanha-termo-aceite-aceito:focus,
.campanha-termo-aceite-aceito:active {
    border-color: #013729;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 10px #013729 !important;
    border-width: 2px 2px 2px 10px;
    border-style: solid;
}

.mat-green-color {
    color: darkgreen;
    opacity: 0.8;
}

.mat-red-color {
    color: red !important;
    opacity: 0.8;

}

.mat-gray-color {
    color: gray;
    opacity: 0.8;
}

.mat-orange-color {
    color: orange;
    opacity: 0.8;
}

.mat-darkorange-color {
    color: darkorange;
    opacity: 0.8;
}
.mat-blue-color {
    color: blue;
    opacity: 0.8;
}

.material-icons.md-18 { font-size: 18px; width: 18px; height: 18px; line-height: normal; }
.material-icons.md-24 { font-size: 24px; width: 24px; height: 24px; line-height: normal; }
.material-icons.md-26 { font-size: 26px; width: 26px; height: 26px; line-height: normal; }
.material-icons.md-28 { font-size: 28px; width: 28px; height: 28px; line-height: normal; }
.material-icons.md-30 { font-size: 30px; width: 30px; height: 30px; line-height: normal; }
.material-icons.md-32 { font-size: 32px; width: 32px; height: 32px; line-height: normal; }
.material-icons.md-34 { font-size: 34px; width: 34px; height: 34px; line-height: normal; }
.material-icons.md-36 { font-size: 36px; width: 36px; height: 36px; line-height: normal; }
.material-icons.md-48 { font-size: 48px; width: 48px; height: 48px; line-height: normal; }