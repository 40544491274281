@import '@angular/material/theming';
@import './colors.scss';

@font-face {
  font-family: 'Gotham Book';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Book'), url('assets/fonts/Gotham-Book.otf') format('opentype');
}


$wwwroot-primary: mat-palette($md-primary, 500);
$wwwroot-accent:  mat-palette($md-secondary, 500);
$wwwroot-warn:    mat-palette($mat-red);

$light-theme: mat-light-theme($wwwroot-primary, $wwwroot-accent, $wwwroot-warn);
$dark-theme: mat-dark-theme($wwwroot-primary, $wwwroot-accent, $wwwroot-warn);

$custom-typography: mat-typography-config(
  $font-family: 'Gotham Book, sans-serif',

);

@include mat-core-theme($light-theme);
@include mat-autocomplete-theme($light-theme);
@include mat-button-theme($light-theme);
@include mat-button-toggle-theme($light-theme);
@include mat-card-theme($light-theme);
@include mat-checkbox-theme($light-theme);
@include mat-chips-theme($light-theme);
@include mat-table-theme($light-theme);
@include mat-datepicker-theme($light-theme);
@include mat-dialog-theme($light-theme);
@include mat-divider-theme($light-theme);
@include mat-expansion-panel-theme($light-theme);
@include mat-form-field-theme($light-theme);
@include mat-grid-list-theme($light-theme);
@include mat-icon-theme($light-theme);
@include mat-input-theme($light-theme);
@include mat-list-theme($light-theme);
@include mat-menu-theme($light-theme);
@include mat-paginator-theme($light-theme);
@include mat-progress-bar-theme($light-theme);
@include mat-progress-spinner-theme($light-theme);
@include mat-radio-theme($light-theme);
@include mat-select-theme($light-theme);
@include mat-sidenav-theme($light-theme);
@include mat-slide-toggle-theme($light-theme);
@include mat-slider-theme($light-theme);
@include mat-stepper-theme($light-theme);
@include mat-tabs-theme($light-theme);
@include mat-toolbar-theme($light-theme);
@include mat-tooltip-theme($light-theme);
@include mat-snack-bar-theme($light-theme);

@include mat-core($custom-typography);